import React from 'react';
import { Carousel } from 'react-bootstrap';
import Cart from './Cart';
import Productlisting from './Productlisting';
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';

function Home() {
    const womenList = [
        {
            id: 1,
            img: "https://staticimg.titan.co.in/Titan/Catalog/2648WL01_1.jpg?impolicy=pqmed&imwidth=640",
            img1:"https://staticimg.titan.co.in/Titan/Catalog/2648WL01_4.jpg?impolicy=pqlow&imwidth=150",
            name: "Workwear Watch with Grey Dial Leather Strap",
            dec: ' R27015012',
            price: "₹3,195.00",
            newPrice:"₹1,499.00",
            av:"in stock",
            d1:"Minimalistic contemporary design",
            d2:"Genuine Light Olive Green colored leather strap",
            d3:"Dual finished Grey dial with Ink filled hands",
            d4:"Manfucturer Warranty : 2 years",
        }, {
            id: 2,
            img: "https://staticimg.titan.co.in/Titan/Catalog/2588KM03_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Workwear Watch with Black Dial & Stainless Steel Strap",
            dec: ' R27121302',
            price: "₹2,395.00", 
             av:"in stock",
        }, {
            id: 3,
            img: "https://www.rado.com/media/catalog/product/t/r/truethinline_r27120402_sld_web.png?im=Resize=(800,800),aspect=fit;Crop=(0,0,800,800),gravity=Center,allowExpansion",
            name: "True Thinline x Great Gardens of the World",
            dec: ' R27120402',
            price: "₹20800.00",
            av:"in stock",
        }, {
            id: 4,
            img: "https://www.rado.com/media/catalog/product/r/g/rgb_cat_true_thinline_420_0005_3_090_3.png?im=AspectCrop=(1980,1980),allowExpansion,location=(0.5,0.5);Resize=(1980,1980),aspect=fill;Crop=(0,0,1980,1980),gravity=Center",
            name: "True Thinline x Great Gardens of the World",
            dec: ' R27005902',
            price: "₹12800.00",
            av:"in stock",
        }, {
            id: 5,
            img: "https://fossil.scene7.com/is/image/FossilPartners/ME3227_main?$sfcc_fos_large$",
            name: "Fossil Heritage Automatic Two-Tone Stainless Steel Watch",
            dec: '  R27007032',
            price: "₹23,995.00",
            av:"in stock",
        },
        {
            id: 6,
            img: "https://fossil.scene7.com/is/image/FossilPartners/FS5551_main?$sfcc_fos_large$",
            name: "Minimalist Three-Hand Brown Leather Watch",
            dec: 'R27122252',
            price: "₹ 5,997.00",
            av:"in stock",
        },
    ]
    const menList = [
        {
            id: 1,
            img: "https://staticimg.titan.co.in/Titan/Catalog/1805KM03_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Black & Gold Anthracite Dial Stainless Steel Strap Watch",
            dec: ' R27015012',
            price: " ₹ 8,245.00",
            av:"in stock",
        }, {
            id: 2,
            img: "https://staticimg.titan.co.in/Titan/Catalog/1733KL03_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Workwear Watch with Blue Dial & Leather Strap",
            dec: ' R27121302',
            price: "₹5,800.00",
            av:"in stock",
        }, {
            id: 3,
            img: "https://www.rado.com/media/catalog/product/c/a/captaincook_r32129158_sld_web.png?im=AspectCrop=(1980,1980),allowExpansion,location=(0.5,0.5);Resize=(1980,1980),aspect=fill;Crop=(0,0,1980,1980),gravity=Center",
            name: "Captain Cook High-Tech Ceramic Diver",
            dec: ' R27120402',
            price: "₹7900.00",
            av:"in stock",
        }, {
            id: 4,
            img: "https://www.rado.com/media/catalog/product/r/g/rgb_cat_truesquare_734_6086_3_016_3.png?im=AspectCrop=(1980,1980),allowExpansion,location=(0.5,0.5);Resize=(1980,1980),aspect=fill;Crop=(0,0,1980,1980),gravity=Center",
            name: "True Square Automatic Open Heart",
            dec: ' R27005902',
            price: "₹6300.00",
            av:"in stock",
        }, {
            id: 5,
            img: "https://fossil.scene7.com/is/image/FossilPartners/FS4795_main?$sfcc_fos_large$",
            name: "Dean Chronograph Stainless Steel Watch",
            dec: '  R27007032',
            price: "₹5495.00",
            av:"in stock",
        },
        {
            id: 6,
            img: "https://fossil.scene7.com/is/image/FossilPartners/FS5824_main?$sfcc_fos_large$",
            name: "Everett Three-Hand Date Black Stainless Steel Watch",
            dec: 'R27122252',
            price: "₹6400.00",
            av:"in stock",
        },
    ]
    const coupleList = [
        {
            id: 1,
            img: "https://staticimg.titan.co.in/Helios/Catalog/KCWGG2656201PA_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Kenneth Cole Analog Brown Dial Watch for Pair",
            dec: ' R27015012',
            price: "₹5500.00",
            av:"in stock",
        }, {
            id: 2,
            img: "https://staticimg.titan.co.in/Titan/Catalog/17342569KM01_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Bandhan Blue Dial Stainless Steel Strap Watches",
            dec: ' R27121302',
            price: "₹7800.00",
            av:"in stock",
        }, {
            id: 3,
            img: "https://cdn4.ethoswatches.com/the-watch-guide/wp-content/uploads/2017/11/TAG.jpg",
            name: "TAG Heuer Aquaracer",
            dec: ' R27120402',
            price: "₹5250.00",
            av:"in stock",
        }, {
            id: 4,
            img: "https://cdn4.ethoswatches.com/the-watch-guide/wp-content/uploads/2017/11/1.jpg",
            name: "Movado Edge",
            dec: ' R27005902',
            price: "₹7645.00",
            av:"in stock",
        }, {
            id: 5,
            img: "https://staticimg.titan.co.in/Titan/Catalog/18432655QM01_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Titan Edge Pair Blue Dial Watch for Couple",
            dec: '  R27007032',
            price: "₹6800.00",
            av:"in stock",
        },
        {
            id: 6,
            img: "https://staticimg.titan.co.in/Titan/Catalog/9400494204NM01_1.jpg?impolicy=pqmed&imwidth=640",
            name: "Bandhan Black Dial Stainless Steel Strap Watch",
            dec: 'R27122252',
            price: "₹8000.00",
            av:"in stock",
        },
    ]
    return (
        <>
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className="d-block banner w-100"
                        src="img/couplebanner.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption className='h-100 w-100 bg-dark bg-opacity-50 start-0 top-0 d-flex align-items-end justify-content-center'>
                        <div className=''>
                            <h3>Couple Watch</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 banner"
                        src="img/bannermen.jpg"
                        alt="Second slide"
                    />
                    <Carousel.Caption className='h-100 w-100 bg-dark bg-opacity-50 start-0 top-0 d-flex align-items-end justify-content-center'>
                        <div className=''>
                            <h3>Best Collection Of Man Watch</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 banner"
                        src="img/bannerwomen.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption className='h-100 w-100 bg-dark bg-opacity-50 start-0 top-0 d-flex align-items-end justify-content-center'>
                        <div className=''>
                            <h3>The Gretest Collection Of Women Watch</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <section className='py-5'>
                <div className='container text-center'>
                    <h2>Women Collection</h2>
                        <Swiper
                           slidesPerView={4}
                           spaceBetween={30}
                           freeMode={true}
                           pagination={{
                             clickable: true,
                           }}
                           breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 50,
                            },
                        }}
                           modules={[FreeMode, Pagination]}
                           className="mySwiper py-5">
                            {womenList.map((product, index) => {
                                return (
                                    <SwiperSlide>
                                        <Productlisting key={`productdetail-${index}`} item={product}></Productlisting>
                                  </SwiperSlide>
                                )
                            })}
                        </Swiper>
                </div>
            </section>
            <section className='py-5'>
                <div className='container text-center'>
                    <h2>Men Collection</h2>
                        <Swiper
                           slidesPerView={4}
                           spaceBetween={30}
                           freeMode={true}
                           pagination={{
                             clickable: true,
                           }}
                           breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 50,
                            },
                        }}
                           modules={[FreeMode, Pagination]}
                           className="mySwiper py-5">
                            {menList.map((product, index) => {
                                return (
                                    <SwiperSlide>
                                        <Productlisting key={`productdetail-${index}`} item={product}></Productlisting>
                                  </SwiperSlide>
                                )
                            })}
                        </Swiper>
                </div>
            </section>
            <section className='py-5'>
                <div className='container text-center'>
                    <h2>Couple Collection</h2>
                        <Swiper
                           slidesPerView={4}
                           spaceBetween={30}
                           freeMode={true}
                           pagination={{
                             clickable: true,
                           }}
                           breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 50,
                            },
                        }}
                           modules={[FreeMode, Pagination]}
                           className="mySwiper py-5">
                            {coupleList.map((product, index) => {
                                return (
                                    <SwiperSlide>
                                        <Productlisting key={`productdetail-${index}`} item={product}></Productlisting>
                                  </SwiperSlide>
                                )
                            })}
                        </Swiper>
                </div>
            </section>
        </>
    );
}

export default Home;