import React from 'react';

function NoPage() {
    return (
        <div>
            
        </div>
    );
}

export default NoPage;