import React from 'react';

function Contact() {
    return (
        <div>
            hec
        </div>
    );
}

export default Contact;