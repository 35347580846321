import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { BsFillCartPlusFill, BsFillEyeFill, BsStarFill, BsStarHalf } from "react-icons/bs";
import { AiFillStar, IconName } from "react-icons/ai";

function Productlisting(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const { item } = props;
    return (
        <>
            <Col xs={12} >
                <div className='w-100 '>
                    <div className="img-box position-relative overflow-hidden">
                        <img src={item.img} className="w-100" />
                        <div className="icon-box position-absolute w-100 mx-auto">
                            <Button className="btn btn-primary fs-4"  onClick={handleShow}><BsFillEyeFill/></Button >
                            <Button className="btn btn-primary fs-4 ms-3"><BsFillCartPlusFill/></Button >
                        </div>
                    </div>
                    <div>
                        <h5>{item.name}</h5>
                        <p className="text-primary m-0 fs-1 fw-bold">{item.newPrice}</p>
                       <label><s>{item.price}</s></label>
                    </div>
                </div>
            </Col>
            <Modal
            size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-2 fw-semibold">{item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={6}>
                    <img src={item.img}  className="w-100"/>
                </Col>
                <Col xs={6}>
                  <BsStarFill className="text-warning fs-6"/>
                  <BsStarFill className="text-warning fs-6 ms-2"/>
                  <BsStarFill className="text-warning fs-6 ms-2"/>
                  <BsStarFill className="text-warning fs-6 ms-2"/>
                  <BsStarHalf className="text-warning fs-6 mx-2"/> Review
                        <p className="text-primary m-0 fs-1 fw-bold">{item.newPrice}</p>
                      <label className="fw-semibold"><s>MRP {item.price}</s> Off</label>
                    <p className="fw-bold">{item.av}</p>
                    <ul className="p-0">
                      <li>{item.d1}</li>
                      <li>{item.d2}</li>
                      <li>{item.d3}</li>
                      <li>{item.d4}</li>
                    </ul>
                    <Button>ADD TO CART</Button>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    );
}
export default Productlisting;