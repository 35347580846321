import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './assests/layout';
import Home from './assests/Home';
import Cart from './assests/Cart';
import Contact from './assests/Contact';
import NoPage from './assests/NoPage';
import Swiper from 'swiper';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/cart" exact  element={<Cart />} />
            <Route path="/contact" exact  element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;