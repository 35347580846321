import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { IconName } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineShoppingCart, } from "react-icons/ai";

function Layout() {
    var wishvalue = 5;
    var cartvalue = 5;
    return (
        <>
            <Navbar bg="" expand="md" className='shadow fixed-top'>
                <Container >
                    <Navbar.Brand href="#">
                        <img src='img/logo.png' className='img-fluid'/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="mx-auto my-2 my-lg-0 text-center" >
                             <Link className="text-decoration-none text-black mx-3" to="/">Home</Link>
                             <Link className="text-decoration-none text-black mx-3" to="/men">Men</Link>
                             <Link className="text-decoration-none text-black mx-3" to="/women">Women</Link>
                             <Link className="text-decoration-none text-black mx-3" to="/couple">Couple</Link>
                        </Nav>
                       <div className='text-center text-md-start'>
                       <Link className="text-decoration-none btn btn-primary position-relative   fs-3" to="/b"><AiOutlineHeart/><span className='icon'>{wishvalue}</span> </Link>
                        <Link className="text-decoration-none btn btn-primary  position-relative ms-3 fs-3" to="/cart"><AiOutlineShoppingCart/><span className='icon'>{cartvalue}</span></Link>
                       </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
            <footer className='text-center p-3 border-top'>
            Copyright © 2022. All Rights Reserved
            </footer>
        </>
    );
}

export default Layout;